import { useEffect } from "react";
import axios from "../utils/axios";

function SSOLogin() {
  useEffect(() => {
    axios
      .get("/user/self")
      .then(({ data }) => {
        const { userData, isAdmin } = data;
        sessionStorage.setItem("isLoggedIn", true);
        sessionStorage.setItem("userData", JSON.stringify(userData));
        if (isAdmin) sessionStorage.setItem("isAdmin", true);
        window.location.href = "/profile";
      })
      .catch(() => {
        window.location.href = "/";
      });
  }, []);
  return <h1>Loading...</h1>;
}

export default SSOLogin;
