import React, { useEffect, useState } from "react";
import axios from "../utils/axios";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import logo from "../assets/png/hts-logo.png";
const Profile = () => {
  const [userData, setUserData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/user/self")
      .then(({ data }) => {
        const { userData, isAdmin } = data;
        setUserData(userData);
        setIsAdmin(isAdmin);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  return (
    <div className="profileContainer">
      {isLoading && (
        <div className="loadingContainer">
          <CircularProgress />
        </div>
      )}

      <Box
        style={{
          display: "flex",
          alignContent: "center",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "3em",
        }}
        className="profileCard"
      >
        <Box
          style={{
            height: "64px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            width="auto"
            height="32px"
            src={logo}
            alt={"Hutech"}
            style={{ objectFit: "contain" }}
          />
        </Box>
        <Box className="profileCenterContent">
          <Avatar
            style={{ height: "5em", width: "5em" }}
            alt={userData.name}
            src="//"
          />
          <div className="profileContent">
            <div className="profilerow">
              <Typography
                className="profileRowLable"
                variant="h6"
                sx={{ fontWeight: "600" }}
              >
                Name:
              </Typography>

              <Typography className="profileRowValue" variant="h6">
                {userData.name}
              </Typography>
            </div>
            <div className="profilerow">
              <Typography
                className="profileRowLable"
                variant="h6"
                sx={{ fontWeight: "600" }}
              >
                Email:
              </Typography>

              <Typography className="profileRowValue" variant="h6">
                {userData.email}
              </Typography>
            </div>
            <div className="profilerow">
              <Typography
                className="profileRowLable"
                variant="h6"
                sx={{ fontWeight: "600" }}
              >
                Roles:
              </Typography>

              {userData?.access ? (
                <Typography variant="h6" className="profileRowValue">
                  {userData?.access?.map((role) => role.name).join(", ")}
                </Typography>
              ) : (
                <Typography variant="h6" className="profileRowValue">
                  -
                </Typography>
              )}
            </div>
          </div>
        </Box>
        <Box className="profileActions">
          {isAdmin && (
            <Box>
              <Button
                size="large"
                onClick={() => {
                  window.location.href = "/manage-users";
                }}
                variant="contained"
              >
                Launch IAM Dashboard
              </Button>
            </Box>
          )}
          <Box>
            <Button
              variant="text"
              fullWidth
              size="large"
              onClick={() => {
                axios
                  .get("/logout")
                  .then((res) => {
                    sessionStorage.clear();
                    window.location.href = "/";
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Profile;
