import axios from "axios";

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: "https://apis.iam.hutechweb.com",
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error, error.response.status, "##");
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
export default instance;
