import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes, Redirect, Navigate } from "react-router-dom";
import "./App.css";
import Cookies from "universal-cookie";
import axios from "./utils/axios";
import SSOLogin from "./pages/ssologin";
import Profile from "./pages/profile";
import AuthRouter from "./components/authRoute";
const Login = lazy(() => import("./pages/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const ManageRoles = lazy(() => import("./pages/ManageRoles"));
const AccessLogs = lazy(() => import("./pages/AccessLogs"));
const ConfirmSso = lazy(() => import("./pages/ConfirmSso"));
const ManagePolicy = lazy(() => import("./pages/ManagePolicy"));
function App() {
  return (
    <Suspense fallback={<p>Loading</p>}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/ssologin/:token" element={<SSOLogin />} />
        <Route path="/manage-password/:code" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route
          path="/manage-users"
          element={
            <AuthRouter isAdmin={true}>
              <Dashboard />
            </AuthRouter>
          }
        />
        <Route
          path="/view-logs"
          element={
            <AuthRouter isAdmin={true}>
              <AccessLogs />
            </AuthRouter>
          }
        />
        <Route
          path="/manage-sso"
          element={
            <AuthRouter isAdmin={true}>
              <ConfirmSso />
            </AuthRouter>
          }
        />
        <Route
          path="/manage-policy"
          element={
            <AuthRouter isAdmin={true}>
              <ManagePolicy />
            </AuthRouter>
          }
        />
        <Route
          path="/manage-roles"
          element={
            <AuthRouter isAdmin={true}>
              <ManageRoles />
            </AuthRouter>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthRouter>
              <Profile />
            </AuthRouter>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default App;

// Add a fixed delay so you can see the loading state
// function delayForDemo(promise) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, 2000);
//   }).then(() => promise);
// }
