import { useEffect, useState } from "react";
import axios from "../utils/axios";
import { Navigate, useNavigate } from "react-router-dom";

function AuthRouter({ children, isAdmin }) {
  const [isLoading, setLoading] = useState(true);
  const [allow, setAllow] = useState(null);
  useEffect(() => {
    console.log(window.location.pathname, "PP");
    axios
      .get(
        `/auth/access-check/${window.location.pathname
          ?.replace("/", "")
          ?.split("/")
          .join("-")}`
      )
      .then((data) => {
        setAllow(true);
        setLoading(false);
      })
      .catch((err) => {
        if (isAdmin) window.location.href = "/profile";
        else window.location.href = "/";
      });
  }, [window.location.pathname]);
  return isLoading ? "Loading..." : allow ? children : <Navigate to="/" />;
}

export default AuthRouter;
